import {
eventsExample,
shapesExample,
minigame,
database,
defaultModule,
crazyShapes,
tictactoe,
onePlayerPong,
} from "./Lessons/modules";

export const lessons = [
  { label: "Home | Blank | Your Own Code", module: defaultModule, code: "" },
  {
    label: "Hello World",
    module: defaultModule,
    code: "print('Hello World!')",
  },
  {
    label: "Input",
    module: defaultModule,
    code: "name=input('Enter your name:')\nprint('Hello '+ name)",
  },
  { label: "Shapes", module: defaultModule, code: shapesExample },
  { label: "Database", module: defaultModule, code: database },
  { label: "Events", module: defaultModule, code: eventsExample },
  { label: "Crazy Shapes", module: defaultModule, code: crazyShapes },
  { label: "1 Player Pong", module: defaultModule, code: onePlayerPong },
  { label: "Tic-Tac-Toe", module: defaultModule, code: tictactoe },
  { label: "Mini Game", module: defaultModule, code: minigame },

];
//to add
//tic tac toe game
//maze
//drawing with composite shapes like panda