export const eventsExample = "# Example using evens like mouse click, drag, and update\
\nLabel('Green Circle - WASD | Blue Sqaure - Mouse Click and Drag', 200, 15, 10,'black','Times New Roman','normal')\
\nr1=Rect(200,200,50,50,0,'blue')\
\nc1=Circle(200,200, 50,0, None, 'green', 360)\
\nc2=Circle(100,100, 25,0, 'red', 360)\
\n\
\n#variables used in functions\
\n#store these variables in the database\
\ndatabase.c1xDir=0\
\ndatabase.c1yDir=0\
\ndatabase.c2xDir=3\
\ndatabase.c2yDir=-3\
\ndef OnClick(mouseX,mouseY):\
\n    r1.x = mouseX\
\n    r1.y = mouseY\
\n# Example On Key Press Function (a-z)\
\ndef OnKey(key):\
\n    if key=='w':\
\n        database.c1yDir=3\
\n        database.c1xDir=0\
\n    if key=='a':\
\n        database.c1xDir=-3\
\n        database.c1yDir=0\
\n    if key=='s':\
\n        database.c1yDir=-3 \
\n        database.c1xDir=0\
\n    if key=='d':\
\n        database.c1xDir=3\
\n        database.c1yDir=0\
\n\
\ndef OnDrag(mouseX,mouseY):\
\n    r1.x = mouseX\
\n    r1.y = mouseY\
\ndef OnFrameUpdate():\
\n    if c1.IsTouching('left-wall'):\
\n        c1.x = 0+c1.radius+1\
\n        database.c1xDir=0\
\n    elif c1.IsTouching('right-wall'):\
\n        c1.x = 400-c1.radius-1\
\n        database.c1xDir=0\
\n    else:\
\n        c1.x+=database.c1xDir\
\n    if c1.IsTouching('bottom-wall'):\
\n        c1.y = 0+c1.radius+1\
\n        database.c1yDir=0\
\n    elif c1.IsTouching('top-wall'):\
\n        c1.y = 400-c1.radius-1\
\n        database.c1yDir=0\
\n    else:\
\n        c1.y+=database.c1yDir\
\n\
\n    # code for c2\
\n    if c2.IsTouching('left-wall'):\
\n        database.c2xDir=3\
\n    if c2.IsTouching('right-wall'):\
\n        database.c2xDir=-3\
\n    if c2.IsTouching('bottom-wall'):\
\n        database.c2yDir=3\
\n    if c2.IsTouching('top-wall'):\
\n        database.c2yDir=-3\
\n    c2.x+=database.c2xDir\
\n    c2.y+=database.c2yDir\
\n    if r1.IsTouching(c2):\
\n        r1.fillColor='red'\
\n    else:\
\n        r1.fillColor='blue'"
export const shapesExample = "### RECTANGLES ###\
\n# Rect(x, y, width, height, rotation, fillColor, borderColor)\
\n# x,y - center # rotation - degrees rotate clockwise. #optional - rotation, fillColor, borderColor\
\nr1=Rect(200,200, 400, 50,0,None,'blue')\
\nr2=Rect(200,200, 400, 50, 90, None, 'red')\
\n\
\n### Circles ###  \
\n# Circle(x, y, radius, rotation, fillColor, borderColor, degrees)\
\n# x,y - center. # optional-fillColor, borderColor, degrees\
\nc1=Circle(200,200, 200,0, None, 'green',360)\
\nc2=Circle(200,200, 100, 45, None, 'brown',270)\
\n\
\n### Ovals ###\
\n# Oval(x position, y position, width, height, rotation, fillColor, borderColor)\
\n# x,y - center # fillColor, borderColor are optional.\
\no1=Oval(200,200, 200, 400, 45, None, 'blue')\
\no2=Oval(200,200, 200, 400, -45, None, 'purple')\
\n\
\n### Polygons ###\
\n# Polygon(x position, y position, radius, sides, rotation, fillColor, borderColor, degrees)\
\n# x,y - center. # sides - number of sides. # rotation - degrees clockwise # optional - fillColor, borderColor, degrees\
\np1=Polygon(200,200, 100, 5, 0 , 'orange', 'blue')\
\np2=Polygon(200,200, 150, 9, 0 , None, 'green')\
\n\
\n# Star(x position, y position, radius, points, sharpness, rotation, fillColor, borderColor)\
\n# x,y - center # points - number of tips. default is 5\
\n# sharpeness - between 0 (wide) and 1 (narrow), default is 0.5\
\n# rotation is in degrees clockwise # fillColor, borderColor are optional.\
\ns1=Star(200, 200, 25,5, 0.2, 0, 'yellow', 'red') \
\ns2=Star(200, 200, 200, 20, 0.4 ,0, None, 'purple')"
export const minigame = "#mini game\
\nimport random as r\
\nimport math\
\nimport time\
\n\
\n#game room size\
\n#should be no smaller than 2x2. different rows and columns are ok\
\ndatabase.roomRows = 2\
\ndatabase.roomCols = 2\
\n#create player\
\nplayer = Polygon(200,200, 15, 3, 0 , 'green')\
\n#create enemy\
\nenemy = Polygon(r.randint(40,360),r.randint(40,360), 15, 3, 0 , 'red')\
\n\
\n#create the bullet class\
\nclass Bullet:\
\n    def __init__(self, type):\
\n        #type is 'player' or 'enemy'\
\n        self.type = type\
\n        self.shape = Rect(-100,-100, 7, 14, 0, 'green' if type=='player' else 'red', None)\
\n        self.isActive = False\
\n        self.heading = ((0),(1))\
\n\
\n    @property    \
\n    def isActive(self):\
\n        return self._isActive\
\n    @isActive.setter\
\n    def isActive(self, x):\
\n        self._isActive = x\
\n        if x == False:\
\n            self.shape.x = -100\
\n            self.shape.y = -100\
\n            self.heading = 'right'\
\n        else:\
\n            self.shape.x = player.x if self.type =='player' else enemy.x\
\n            self.shape.y = player.y if self.type =='player' else enemy.y\
\n            self.shape.rotation = player.rotation if self.type == 'player' else enemy.rotation \
\n            angle = (self.shape.rotation + 90)*math.pi/180\
\n            self.heading = ((math.cos(angle)),(math.sin(angle)))\
\n    def Move(self):\
\n        if self.isActive:\
\n            if self.shape.x < 0 or self.shape.x > 400 or self.shape.y < 0 or self.shape.y> 400:\
\n                self.isActive = False\
\n            else:\
\n                self.shape.x+=15*self.heading[0]\
\n                self.shape.y+=15*self.heading[1]\
\n        \
\n            if self.type == 'player' and self.shape.IsTouching(enemy):\
\n                #kill or decrease enemy heaalth\
\n                self.isActive = False\
\n            if self.type == 'enemy' and self.shape.IsTouching(player):\
\n                #kill or decrease player health\
\n                self.isActive = False\
\n\
\n#function to build any wall with doors (doors are tuple positions)\
\ndef CreateWallWithDoors(side, listOfDoors):\
\n    #CreateWallWithDoors('top', [(100,160), (300,360)]) #CreateWallWithDoors('bot', [(170,230)])\
\n    #CreateWallWithDoors('right', [(170,230)]) #CreateWallWithDoors('left', [])\
\n    doors=[]\
\n    step = 0\
\n    #draw rectangles for each door opening\
\n    for door in listOfDoors:\
\n        #wh is width or height\
\n        wh = door[0] - step\
\n        center = wh/2+step\
\n        doors.append(Rect(\
\n            (7 if side=='left' else 393) if (side == 'left' or side =='right') else center, \
\n            (7 if side=='bot' else 393) if (side == 'bot' or side =='top') else center ,\
\n            14 if (side == 'left' or side == 'right') else wh, \
\n            14 if (side == 'bot' or side =='top') else wh , 0, 'blue', None))\
\n        step = door[1]\
\n    #build the end rectangle or last rectangle if no doors in list\
\n    if len(listOfDoors) == 0:\
\n        step = 0\
\n    wh = 400 - step\
\n    center = wh/2+step\
\n    doors.append(Rect(\
\n        (7 if side=='left' else 393) if (side == 'left' or side =='right') else center, \
\n        (7 if side=='bot' else 393) if (side == 'bot' or side =='top') else center ,\
\n        14 if (side == 'left' or side == 'right') else wh, \
\n        14 if (side == 'bot' or side =='top') else wh , 0, 'blue', None\
\n        ))\
\n    return doors\
\n    \
\n#database values\
\ndatabase.playerDir = None\
\ndatabase.bullets = []\
\ndatabase.ebullets = []\
\ndatabase.timer1 = time.time()\
\n\
\nfor i in range(50):\
\n    database.bullets.append(Bullet('player'))\
\nfor i in range(50):\
\n    database.ebullets.append(Bullet('enemy'))\
\n\
\ndef ChangeRoom(room):\
\n    #destroy old doors - if top exists others do too\
\n    if hasattr(database,'top'):\
\n        for wall in database.top + database.bot + database.left + database.right:\
\n            wall.Clear()\
\n            del wall\
\n    # top wall\
\n    if room <= database.roomRows:\
\n        database.top = CreateWallWithDoors('top', [])\
\n    else:\
\n        database.top = CreateWallWithDoors('top', [(170,230)])\
\n    # bot wall\
\n    if room > (database.roomRows*database.roomCols-database.roomRows):\
\n        database.bot = CreateWallWithDoors('bot', [])\
\n    else:\
\n        database.bot = CreateWallWithDoors('bot', [(170,230)])\
\n    # left wall\
\n    if (room % (database.roomRows)) == 1:\
\n        database.left = CreateWallWithDoors('left', [])\
\n    else:\
\n        database.left = CreateWallWithDoors('left', [(170,230)])\
\n    # right wall\
\n    if (room % (database.roomRows)) == 0:\
\n        database.right = CreateWallWithDoors('right', [])\
\n    else:\
\n        database.right = CreateWallWithDoors('right', [(170,230)])\
\n    # set room number in database\
\n    database.room = room\
\n    \
\n    # randomize enemy location\
\n    enemy.x = r.randint(40,360)\
\n    enemy.y = r.randint(40,360)\
\n    \
\n    # deactivate all bullets\
\n    for bullet in database.bullets+database.ebullets:\
\n        if bullet.isActive:    \
\n            bullet.isActive = False\
\n    #reset timer\
\n        database.timer1 = time.time()\
\n#more database values and initialize room\
\ndatabase.room = 1\
\nChangeRoom(1)\
\n    \
\ndef OnKey(key):\
\n    if key =='w':\
\n        player.rotation = 0\
\n        database.playerDir = 'up'\
\n    if key =='a':\
\n        player.rotation = 90\
\n        database.playerDir = 'left'\
\n    if key =='s':\
\n        player.rotation = 180\
\n        database.playerDir = 'down'\
\n    if key =='d':\
\n        player.rotation = -90\
\n        database.playerDir = 'right'\
\n    if key =='q':\
\n        database.playerDir = None\
\n    if key == 'space':\
\n        for bullet in database.bullets:\
\n            if not bullet.isActive:\
\n                bullet.isActive = True\
\n                break\
\n\
\ndef OnFrameUpdate():\
\n    #control player movement\
\n    if database.playerDir == 'up':\
\n        player.y+=3\
\n    elif database.playerDir == 'left':\
\n        player.x-=3\
\n    elif database.playerDir == 'down':\
\n        player.y-=3\
\n    elif database.playerDir == 'right':\
\n        player.x+=3\
\n    #else no movement\
\n    \
\n    #enemy looks at player\
\n    #using (a·b)/(|a||b|)\
\n    a = (player.x - enemy.x, player.y - enemy.y)\
\n    b = (1, 0)\
\n    num = a[0]*b[0]+a[1]*b[1]\
\n    den = (a[0]**2+a[1]**2)**(1/2)\
\n    rotation = math.degrees(math.acos(num/den)) - 90\
\n    if player.y < enemy.y:\
\n        rotation = -rotation + 180\
\n    enemy.rotation = rotation\
\n    #enemy fires every 2 seconds\
\n    now = time.time()\
\n    if now - database.timer1 > 2:\
\n        #enemy fires a bullet\
\n        for bullet in database.ebullets:\
\n            if not bullet.isActive:\
\n                bullet.isActive = True\
\n                break\
\n        #reset timer\
\n        database.timer1 = now\
\n    \
\n    #check collisions and stop the player\
\n    for wall in database.top:\
\n        if player.IsTouching(wall):\
\n            player.y -= 5\
\n            database.playerDir = None\
\n    for wall in database.bot:\
\n        if player.IsTouching(wall):\
\n            player.y += 5\
\n            database.playerDir = None\
\n    for wall in database.left:\
\n        if player.IsTouching(wall):\
\n            player.x += 5\
\n            database.playerDir = None\
\n    for wall in database.right:\
\n        if player.IsTouching(wall):\
\n            player.x -= 5\
\n            database.playerDir = None\
\n    \
\n    # Change Room Code\
\n    if player.y > 400:\
\n        ChangeRoom(database.room-database.roomRows)\
\n        player.y = 0\
\n    if player.y < 0:\
\n        ChangeRoom(database.room+database.roomRows)\
\n        player.y = 400\
\n    if player.x > 400:\
\n        ChangeRoom(database.room+1)\
\n        player.x = 0\
\n    if player.x < 0:\
\n        ChangeRoom(database.room-1)\
\n        player.x = 400\
\n\
\n    for bullet in database.bullets+database.ebullets:\
\n        if bullet.isActive:    \
\n            bullet.Move()\
\n        \
\n        "
export const database = "#database is a built in object\
\n#it allows the user to store any datatype as a variable\
\n#you can retrieve values or change values\
\n\
\ndatabase.name='chris'\
\ndatabase.id=12\
\ndatabase.gpa=3.7\
\ndatabase.grades=[98.3, 67.8, 77.2, 50.7]\
\n\
\n#show all values in the database\
\ndatabase.show() # or print(database)\
\n\
\n#modify gpa variable\
\ndatabase.gpa=4.0 \
\n\
\n#access grades and create new variable called average\
\ndatabase.average=sum(database.grades)/len(database.grades)\
\n\
\n#show all values to see the database change\
\nprint(database)\
\n\
\n#database values can be accessed anywhere in your code\
\n#example of accessing a database value in a function\
\ndef PrintStuff(something):\
\n    print(something, database.name)\
\n\
\nPrintStuff('hello')"
export const defaultModule = "import turtle\
\nimport math\
\nimport time\
\n_dragpen = turtle.Turtle()\
\n_screen = turtle.Screen()\
\n\
\n# Generic Global Data\
\nclass _Data():                              \
\n    def __get__(self, obj):                 \
\n        return getattr(obj, self.attr)\
\n    def __set__(self, obj, value):                   \
\n        setattr(obj, self.attr, value)\
\n    def __str__(self):\
\n        #print('######### DATABASE  Values########')\
\n        result='######### DATABASE  Values########\\n'\
\n        for attr in dir(self):\
\n            if str(attr)[0] != '_' and str(attr) != 'show':\
\n                #print(str(attr), ' = ', getattr(self,attr))\
\n                result+=str(attr)+' = '+str(getattr(self,attr))+'\\n'\
\n        #print('######## DATABASE END #########')\
\n        result+='######## End of DATBASE #########\\n'\
\n        return result\
\n    def show(self):\
\n        print(self)\
\ndatabase = _Data()\
\nclass MainCamera:\
\n    def __init__(self, x, y):\
\n        self._x = x\
\n        self._y = y\
\n        self.min_x = x-201\
\n        self.min_y = y-201\
\n        self.max_x = x+201\
\n        self.max_y = y+201\
\n        #self._Move()\
\n    def _Move(self):\
\n        _screen.setworldcoordinates(self.min_x, self.min_y, self.max_x, self.max_y)\
\n        _screen.update()\
\n        _screen.tracer(0,0)\
\n        #_dragpen.up()\
\n        #_dragpen.goto(self._x, self._y)\
\n        #_dragpen.shape('bg')\
\n        #_dragpen.color(0,0,0,0.05)\
\n        #_screen.update()\
\n    @property\
\n    def x(self):\
\n        return self._x\
\n    # x. setter\
\n    @x.setter\
\n    def x(self, x):\
\n        self._x = x\
\n        self.min_x = x-201\
\n        self.max_x = x+201\
\n        self._Move()\
\n    @property\
\n    def y(self):\
\n        return self._y\
\n    # y setter\
\n    @y.setter\
\n    def y(self, y):\
\n        self._y = y\
\n        self.min_y = y-201\
\n        self.max_y = y+201\
\n        self._Move()\
\nCamera = MainCamera(200,200)\
\ndef Prefix():\
\n    _screen.setworldcoordinates(-1,-1,401,401)\
\n    _screen.update()\
\n    _screen.tracer(0,0)\
\n    _dragpen.up()\
\n    _dragpen.goto(200,200)\
\n    _dragpen.shape('bg')\
\n    _dragpen.color(0,0,0,0.05)\
\n    _screen.update()\
\ndef Suffix():\
\n    try:\
\n        turtle.Screen().onclick(OnClick)\
\n    except:\
\n        pass\
\n    try:\
\n        for n in range(97, 123):\
\n            turtle.Screen().onkey(lambda n=n: OnKey(chr(n)), chr(n))\
\n    except:\
\n        pass\
\n    try:\
\n        turtle.Screen().listen()\
\n    except:\
\n        pass\
\ndef RunAfter(function_to_decorate):\
\n    def wrapper(*args, **kw):\
\n        # Calling your function\
\n        output = function_to_decorate(*args, **kw)\
\n        # Below this line you can do post processing\
\n        _screen.update()\
\n        return output\
\n    return wrapper\
\n\
\n# def OnClickWrapper(OnClickFunction):\
\n#     def wrapper(*args, **kw):\
\n#         print(args,kw)\
\n#         def clicked(x,y):\
\n#             print('clicked',x,y)\
\n#             args[0] = x\
\n#             args[1] = y\
\n#             print('h', args, kw)\
\n#             OnClickFunction(*args, **kw)\
\n#             _screen.update()\
\n#         return clicked\
\n#     return wrapper\
\n\
\ndef rect(r, theta):\
\n    x = r * math.cos(math.radians(theta))\
\n    y = r * math.sin(math.radians(theta))\
\n    return [x,y]\
\ndef polar(x, y):\
\n    #returns r, theta(degrees)\
\n    r = (x ** 2 + y ** 2) ** .5\
\n    theta = math.degrees(math.atan2(y,x))\
\n    return [r, theta]\
\ndef rotate(x,y,xo,yo,theta): #rotate x,y around xo,yo by theta (rad)\
\n    # -theta for clockwise, theta for counterclockwise.\
\n    xr=math.cos(theta)*(x-xo)-math.sin(theta)*(y-yo)   + xo\
\n    yr=math.sin(theta)*(x-xo)+math.cos(theta)*(y-yo)  + yo\
\n    return [xr,yr]\
\ndef SAT_Collision(p1,p2):\
\n    poly1=p1\
\n    poly2=p2\
\n    for shape in range(2):\
\n        #swap in iteration 2\
\n        if shape==1:\
\n            poly1=p2\
\n            poly2=p1\
\n        #go through each edge and create a projected axis\
\n        for a in range(len(poly1)):\
\n            b = (a+1) % len(poly1)\
\n            axisProj = (-(poly1[b][1]-poly1[a][1]),(poly1[b][0] - poly1[a][0]))\
\n\
\n            # Project the first shape onto the axis of projects and store min and max values\
\n            for p in range(len(poly1)):\
\n                #calculate dot product between point and axis of projection\
\n                q = (poly1[p][0]*axisProj[0] + poly1[p][1]*axisProj[1])\
\n                if p == 0:\
\n                    min_r1 = q\
\n                    max_r1 = q\
\n                else:\
\n                    if(q<min_r1):\
\n                        min_r1 = q\
\n                    if(q>max_r1):\
\n                        max_r1 = q\
\n            # Project the second shape onto the axis of projects and store min and max values\
\n            for p in range(len(poly2)):\
\n                #calculate dot product between point and axis of projection\
\n                q = (poly2[p][0]*axisProj[0] + poly2[p][1]*axisProj[1])\
\n                if p == 0:\
\n                    min_r2 = q\
\n                    max_r2 = q\
\n                else:\
\n                    if(q<min_r2):\
\n                        min_r2 = q\
\n                    if(q>max_r2):\
\n                        max_r2 = q\
\n            \
\n            #check overlaps of min and max\
\n            if (not(max_r2 >= min_r1 and max_r1 >= min_r2)):\
\n                return False\
\n    return True\
\n\
\n# Generic Object\
\nclass Object:\
\n    @property\
\n    def x(self):\
\n         return self._x\
\n    # x. setter\
\n    @x.setter\
\n    def x(self, x):\
\n         self._x = x\
\n         self._Draw()\
\n    @property\
\n    def y(self):\
\n         return self._y\
\n    # y setter\
\n    @y.setter\
\n    def y(self, y):\
\n         self._y = y\
\n         self._Draw()\
\n    @property\
\n    def fillColor(self):\
\n        return self._fillColor\
\n    @fillColor.setter\
\n    def fillColor(self,fillColor):\
\n        self._fillColor = fillColor\
\n        self._Draw()\
\n    def Clear(self):\
\n        self._pen.reset()\
\n        self._pen.hideturtle()\
\n        self._pen.up()\
\n        self._poly=[[]]\
\n# Generic Shape\
\nclass Shape(Object):\
\n    @property\
\n    def rotation(self):\
\n        return self._rotation\
\n    @rotation.setter\
\n    def rotation(self,rotation):\
\n        self._rotation = rotation%360\
\n        self._Draw()\
\n    @property\
\n    def borderColor(self):\
\n        return self._borderColor\
\n    @borderColor.setter\
\n    def borderColor(self,borderColor):\
\n        self._fillColor = borderColor\
\n        self._Draw()\
\n    @property #no getter for poly\
\n    def poly(self):\
\n        return self._poly\
\n    def IsTouching(self, other):\
\n        if isinstance(other, Shape):\
\n            #SAT collision\
\n            #all shapes are made of a set of convex polygons\
\n            for p1 in self._poly:\
\n                for p2 in other._poly:\
\n                    isColliding = SAT_Collision(p1,p2)\
\n                    if isColliding:\
\n                        return True\
\n            return False\
\n        if isinstance(other,list):\
\n            for p1 in self._poly:\
\n                for p2 in other:\
\n                    isColliding = SAT_Collision(p1,p2)\
\n                    if isColliding:\
\n                        return True\
\n            return False\
\n        if isinstance(other, str):\
\n            wallWidth = 60\
\n            if other == 'top-wall':\
\n                return self.IsTouching([[(-wallWidth,400),(400+wallWidth,400),(400+wallWidth,400+wallWidth),(-wallWidth,400+wallWidth)]])\
\n            elif other == 'right-wall':\
\n                if self.IsTouching([[(400,-wallWidth),(400,400+wallWidth),(400+wallWidth,400+wallWidth),(400+wallWidth,-wallWidth)]]):\
\n                    return True\
\n            elif other == 'bottom-wall':\
\n                if self.IsTouching([[(-wallWidth,0),(400+wallWidth,0),(400+wallWidth,-wallWidth),(-wallWidth,-wallWidth)]]):\
\n                    return True\
\n            elif other == 'left-wall':\
\n                if self.IsTouching([[(0,-wallWidth),(0,400+wallWidth),(-wallWidth,400+wallWidth),(-wallWidth,-wallWidth)]]):\
\n                    return True\
\n            return False\
\n    def WasClicked(self, x, y): #given the x and y position of the mouse when clicked\
\n        return self.IsTouching([[(x, y)]])\
\n# Rectangles\
\nclass Rect(Shape):\
\n    def __init__(self,x, y, width, height, rotation=0, fillColor=None, borderColor='black'):\
\n        self._pen = turtle.Turtle()\
\n        self._x = x\
\n        self._y = y\
\n        self._width = width\
\n        self._height = height\
\n        self._rotation = rotation\
\n        self._fillColor = fillColor\
\n        self._poly=[[]]\
\n        if (borderColor == None and fillColor == None):\
\n            self._borderColor = 'black'\
\n        elif (borderColor == None and fillColor != None):\
\n            self._borderColor = fillColor\
\n        else:\
\n            self._borderColor= borderColor\
\n\
\n        #draw shape\
\n        self._Draw()\
\n        self._pen.update()\
\n    def _Draw(self):\
\n        self.Clear()\
\n        self._pen.setheading(0)\
\n        self._pen.pencolor(self._borderColor)\
\n        self._pen.goto(self._x,self._y)\
\n        self._pen.left(self._rotation)\
\n        self._pen.forward(self._width/2)\
\n        self._pen.left(90)\
\n        self._pen.forward(self._height/2)\
\n        self._pen.left(90)\
\n        self._pen.down()\
\n        if(self._fillColor != None):\
\n            self._pen.fillcolor(self._fillColor)\
\n            self._pen.begin_fill()\
\n        for i in range(2):\
\n            self._pen.forward(self._width)\
\n            self._poly[0].append((self._pen.xcor(), self._pen.ycor()))\
\n            self._pen.left(90)\
\n            self._pen.forward(self._height)\
\n            self._poly[0].append((self._pen.xcor(), self._pen.ycor()))\
\n            self._pen.left(90)\
\n        if(self._fillColor != None):\
\n            self._pen.end_fill()\
\n        self._pen.up()\
\n    #custom to Rectangles\
\n    @property\
\n    def width(self):\
\n         return self._width\
\n    @width.setter\
\n    def width(self, width):\
\n         self._width = width\
\n         self._Draw()\
\n    @property\
\n    def height(self):\
\n         return self._height\
\n    @height.setter\
\n    def height(self, height):\
\n         self._height = height\
\n         self._Draw()\
\n# Circles\
\nclass Circle(Shape):\
\n    def __init__(self, x, y, radius, rotation=0, fillColor=None, borderColor='black', degrees=360):\
\n        self._pen = turtle.Turtle()\
\n        self._x = x\
\n        self._y = y\
\n        self._radius = radius\
\n        self._degrees = degrees\
\n        self._rotation=rotation\
\n        self._fillColor = fillColor\
\n        self._poly=[[]]\
\n        if (borderColor == None and fillColor == None):\
\n            self._borderColor = 'black'\
\n        elif (borderColor == None and fillColor != None):\
\n            self._borderColor = fillColor\
\n        else:\
\n            self._borderColor = borderColor\
\n        # Draw\
\n        self._Draw()\
\n        self._pen.update()\
\n    def _Draw(self):\
\n        self.Clear()        \
\n        step=int(self._radius//1.2)\
\n        if step < 10:\
\n            step = 10\
\n        positions=[]\
\n        for i in range(step):\
\n            if i/step <= self._degrees/360:\
\n                px=self._radius * math.cos(2 * math.pi * i / step)\
\n                py=self._radius * math.sin(2 * math.pi * i / step)\
\n                positions.append([px,py])\
\n        # rotates poly\
\n        if(self._rotation!=0):\
\n            for pos in positions:\
\n                [pos[0], pos[1]] = rotate(pos[0], pos[1], 0, 0, math.radians(self._rotation))\
\n        # shift to real center x,y\
\n        for pos in positions:\
\n            pos[0]+=self._x\
\n            pos[1]+=self._y\
\n        self._pen.goto(positions[-1][0],positions[-1][1])\
\n        self._pen.down()\
\n        self._pen.pencolor(self._borderColor)\
\n        if(self._fillColor != None):\
\n            self._pen.fillcolor(self._fillColor)\
\n            self._pen.begin_fill()\
\n        for pos in positions:\
\n            self._pen.goto(pos[0],pos[1])\
\n            self._poly[0].append((pos[0],pos[1]))\
\n        if(self._fillColor !=None):\
\n            self._pen.end_fill()\
\n        self._pen.up()\
\n    #custom to circles\
\n    @property\
\n    def radius(self):\
\n         return self._radius\
\n    @radius.setter\
\n    def radius(self, radius):\
\n         self._radius = radius\
\n         self._Draw()\
\n    @property\
\n    def degrees(self):\
\n         return self._degrees\
\n    @degrees.setter\
\n    def degrees(self, degrees):\
\n         self._degrees = degrees\
\n         self._Draw()\
\n# Ovals\
\nclass Oval(Shape):\
\n    def __init__(self,x, y, width, height, rotation=0, fillColor=None, borderColor='black'):\
\n        self._pen=turtle.Turtle();\
\n        self._x=x\
\n        self._y=y\
\n        self._width=width\
\n        self._height=height\
\n        self._rotation=rotation\
\n        self._fillColor = fillColor\
\n        self._poly=[[]]\
\n        if (borderColor == None and fillColor == None):\
\n            self._borderColor = 'black'\
\n        elif (borderColor == None and fillColor != None):\
\n            self._borderColor = fillColor\
\n        else:\
\n            self._borderColor = borderColor\
\n        self._Draw()\
\n        self._pen.update()\
\n        \
\n    def _Draw(self):\
\n        self.Clear()\
\n        a=self._width//2\
\n        b=self._height//2\
\n        self._pen.goto(0, 0)\
\n        positions=[]\
\n        if(a>b):\
\n            r=a\
\n        else:\
\n            r=b\
\n        step = 1\
\n        for i in range(-a,a, step):\
\n            positions.append([i, ((b/a)*(math.sqrt(a**2 - i**2)))])\
\n        for i in range(a,-a-1, -step):\
\n            positions.append([i, (-(b/a)*(math.sqrt(a**2 - i**2)))])\
\n        \
\n        if(self._rotation!=0):\
\n            for pos in positions:\
\n                [pos[0], pos[1]] = rotate(pos[0], pos[1], 0, 0, math.radians(self._rotation))\
\n        # shift to real center x,y\
\n        for pos in positions:\
\n            pos[0]+=self._x\
\n            pos[1]+=self._y\
\n        self._pen.goto(positions[0][0],positions[0][1])\
\n        self._pen.pencolor(self._borderColor)\
\n        self._pen.down()\
\n        if(self._fillColor != None):\
\n            self._pen.pencolor(self._fillColor)\
\n            self._pen.begin_fill()\
\n        for pos in positions:\
\n            self._poly[0].append((pos[0],pos[1]))\
\n            self._pen.goto(pos[0],pos[1])\
\n        if(self._fillColor != None):\
\n            self._pen.end_fill()\
\n        self._pen.up()\
\n    #custom to Ovals\
\n    @property\
\n    def width(self):\
\n         return self._width\
\n    @width.setter\
\n    def width(self, width):\
\n         self._width = width\
\n         self._Draw()\
\n    @property\
\n    def height(self):\
\n         return self._height\
\n    @height.setter\
\n    def height(self, height):\
\n         self._height = height\
\n         self._Draw()\
\n# POLYGONS\
\nclass Polygon(Shape):\
\n    def __init__(self,x, y, radius, edges, rotation=0, fillColor=None, borderColor='black', degrees=360):\
\n        self._pen = turtle.Turtle()\
\n        self._x = x\
\n        self._y = y\
\n        self._radius = radius\
\n        self._edges = edges\
\n        self._rotation = rotation\
\n        self._fillColor = fillColor\
\n        self._rotation=rotation\
\n        self._degrees=degrees\
\n        self._poly=[[]]\
\n        if (borderColor == None and fillColor == None):\
\n            self._borderColor = 'black'\
\n        elif (borderColor == None and fillColor != None):\
\n            self._borderColor = fillColor\
\n        else:\
\n            self._borderColor = borderColor\
\n        self._Draw()\
\n        self._pen.update()\
\n        #Draw\
\n    def _Draw(self):\
\n        self.Clear()        \
\n        positions=[]\
\n        for i in range(self._edges):\
\n            if i/self._edges <= self._degrees/360:\
\n                px=self._radius * math.cos(2 * math.pi * i / self._edges)\
\n                py=self._radius * math.sin(2 * math.pi * i / self._edges)\
\n                positions.append([px,py])\
\n        # rotates poly for flat bottom\
\n        for pos in positions:\
\n            #*1 - flat top *2 - flat right *3-flat bottom *4 - flat left\
\n            [pos[0], pos[1]] = rotate(pos[0], pos[1], 0, 0, math.radians(90/self._edges*3))\
\n        # rotates poly\
\n        if(self._rotation!=0):\
\n            for pos in positions:\
\n                [pos[0], pos[1]] = rotate(pos[0], pos[1], 0, 0, math.radians(self._rotation))\
\n        # shift to real center x,y\
\n        for pos in positions:\
\n            pos[0]+=self._x\
\n            pos[1]+=self._y\
\n        self._pen.goto(positions[-1][0],positions[-1][1])\
\n        self._pen.down()\
\n        self._pen.pencolor(self._borderColor)\
\n        if(self._fillColor != None):\
\n            self._pen.fillcolor(self._fillColor)\
\n            self._pen.begin_fill()\
\n        for pos in positions:\
\n            self._pen.goto(pos[0],pos[1])\
\n            self._poly[0].append((pos[0],pos[1]))\
\n        if(self._fillColor !=None):\
\n            self._pen.end_fill()\
\n        self._pen.up()\
\n    #custom to Polygons\
\n    @property\
\n    def radius(self):\
\n         return self._radius\
\n    @radius.setter\
\n    def radius(self, radius):\
\n         self._radius = radius\
\n         self._Draw()\
\n    @property\
\n    def edges(self):\
\n         return self._edges\
\n    @edges.setter\
\n    def edges(self, edges):\
\n         self._edges = edges\
\n         self._Draw()\
\n# Stars\
\nclass Star(Shape):\
\n    def __init__(self, x, y, radius, points=5, sharpness=0.5, rotation=0, fillColor=None, borderColor='black'):\
\n        self._pen = turtle.Turtle()\
\n        self._x = x\
\n        self._y = y\
\n        self._radius = radius\
\n        self._points = points\
\n        self._sharpness = sharpness\
\n        self._rotation = rotation\
\n        self._fillColor = fillColor\
\n        self._poly=[]\
\n        if (borderColor == None and fillColor == None):\
\n            self._borderColor = 'black'\
\n        elif (borderColor == None and fillColor != None):\
\n            self._borderColor = fillColor\
\n        else:\
\n            self._borderColor = borderColor\
\n        self._Draw()\
\n        self._pen.update()\
\n    def _Draw(self):\
\n        self.Clear() \
\n        angle=(360/self._points)\
\n        #draw star at the origin, then rotate at the orgin, then shift to x,y\
\n        fx = 0\
\n        fy = self._radius\
\n        #positions to store points\
\n        positions=[]\
\n        #calculate inner radius for sharpness\
\n        ri=abs(self._radius*math.cos(math.radians(angle/2)))\
\n        #store the rest of the star positions\
\n        for i in range(1,self._points+1):\
\n            [sx, sy] = rect(ri*(1-self._sharpness), 90-(i+0.5-1)*angle)\
\n            [nx, ny] = rect(self._radius, 90-i*angle)\
\n            positions.append([sx, sy])\
\n            positions.append([nx, ny])\
\n        # rotate star if rotation is not 0 degrees\
\n        if(self._rotation!=0):\
\n            for pos in positions:\
\n                [pos[0], pos[1]] = rotate(pos[0], pos[1], 0, 0, math.radians(self._rotation))\
\n        # shift to real center x,y\
\n        for pos in positions:\
\n            pos[0]+=self._x\
\n            pos[1]+=self._y\
\n        #draw star\
\n        self._poly.clear()\
\n        self._pen.goto(positions[0][0],positions[0][1])\
\n        self._poly.append([(self._x,self._y),(positions[0][0],positions[0][1]),(positions[-1][0],positions[-1][1]),(positions[-2][0],positions[-2][1])])\
\n        self._pen.pencolor(self._borderColor)\
\n        self._pen.down()\
\n        if(self._fillColor != None):\
\n            self._pen.fillcolor(self._fillColor)\
\n            self._pen.begin_fill()\
\n        for i in range(0,len(positions)):\
\n            if i%2==1 and i!=len(positions)-1:\
\n                self._poly.append([(self._x,self._y),(positions[i-1][0],positions[i-1][1]),(positions[i][0],positions[i][1]),(positions[i+1][0],positions[i+1][1])])\
\n            self._pen.goto(positions[i][0],positions[i][1])\
\n        self._pen.goto(positions[0][0],positions[0][1])\
\n        if(self._fillColor != None):\
\n            self._pen.end_fill()\
\n        self._pen.up()\
\n    #custom to Stars\
\n    @property\
\n    def radius(self):\
\n         return self._radius\
\n    @radius.setter\
\n    def radius(self, radius):\
\n         self._radius = radius\
\n         self._Draw()\
\n    @property\
\n    def points(self):\
\n         return self._points\
\n    @points.setter\
\n    def points(self, points):\
\n         self._points = points\
\n         self._Draw()\
\n    @property\
\n    def sharpness(self):\
\n         return self._sharpness\
\n    @sharpness.setter\
\n    def sharpness(self, sharpness):\
\n         self._sharpness = sharpness\
\n         self._Draw()\
\nclass Label(Object):\
\n    def __init__(self, text, x, y, size=8, textColor='black', font='Arial', style='normal'):\
\n        self._pen = turtle.Turtle()\
\n        self._x = x\
\n        self._y = y\
\n        self._text = text\
\n        self._size = size\
\n        self._font = font\
\n        self._style = style\
\n        self._fillColor = textColor\
\n        self._Draw()\
\n        self._pen.update()\
\n    def _Draw(self):\
\n        self.Clear()\
\n        self._pen.goto(self._x,self._y)\
\n        self._pen.color(self._fillColor)\
\n        self._pen.write(self._text,True, align='center',font=(self._font, self._size, self._style))\
\n        #self._pen.showturtle()\
\n    @property\
\n    def text(self):\
\n         return self._text\
\n    @text.setter\
\n    def text(self, text):\
\n         self._text = text\
\n         self._Draw()\
\n    @property\
\n    def size(self):\
\n         return self._size\
\n    @size.setter\
\n    def size(self, size):\
\n         self._size = size\
\n         self._Draw()\
\n    @property\
\n    def font(self):\
\n         return self._font\
\n    @font.setter\
\n    def font(self, font):\
\n         self._font = font\
\n         self._Draw()\
\n    @property\
\n    def style(self):\
\n         return self._style\
\n    @style.setter\
\n    def style(self, style):\
\n         self._style = style\
\n         self._Draw()\
\nclass Button(Shape):\
\n    def __init__(self, text, x, y, size=8, textColor='black', font='Arial', style='normal'):\
\n        self._pen = turtle.Turtle()\
\n        self._x = x\
\n        self._y = y\
\n        self._text = text\
\n        self._size = size #size gives the font height/2\
\n        self._font = font\
\n        self._style = style\
\n        self._fillColor = textColor\
\n        self._padding = 5\
\n        self._Draw()\
\n        self._pen.update()\
\n    def _Draw(self):\
\n        self.Clear()\
\n        self._pen.goto(self._x,self._y)\
\n        self._pen.write(self._text, True, align='center',font=(self._font, self._size, self._style))\
\n        #self._pen.goto(self._x+self._pen.xcor(), self._y+self._pen.ycor())\
\n        self._width = 2*(self._pen.xcor())\
\n        if self._width < 0:\
\n            self._width*=-1\
\n        #print(self._width)\
\n        self._pen.goto(self._x - self._width/2 - self._padding, self._y - self._padding)\
\n        self._pen.setheading(0)\
\n        self._poly=[[]]\
\n        self._pen.down()\
\n        for i in range(2):\
\n            self._pen.forward(self._width + 2*self._padding)\
\n            self._poly[0].append((self._pen.xcor(), self._pen.ycor()))\
\n            self._pen.left(90)\
\n            self._pen.forward(self._size + 2*self._padding)\
\n            self._poly[0].append((self._pen.xcor(), self._pen.ycor()))\
\n            self._pen.left(90)\
\n        self._pen.up()\
\n        self._pen.goto(self._x-self._width/2 - self._padding, self._y - self._padding)\
\n        self._pen.color(self._fillColor)\
\n\
\n    @property\
\n    def text(self):\
\n         return self._text\
\n    @text.setter\
\n    def text(self, text):\
\n         self._text = text\
\n         self._Draw()\
\n    @property\
\n    def size(self):\
\n         return self._size\
\n    @size.setter\
\n    def size(self, size):\
\n         self._size = size\
\n         self._Draw()\
\n    @property\
\n    def font(self):\
\n         return self._font\
\n    @font.setter\
\n    def font(self, font):\
\n         self._font = font\
\n         self._Draw()\
\n    @property\
\n    def style(self):\
\n         return self._style\
\n    @style.setter\
\n    def style(self, style):\
\n         self._style = style\
\n         self._Draw()\
\n\
\n#time.sleep(2.5)"
export const crazyShapes = "# shapes\
\nr1=Rect(200,200, 400, 50,0,None,'blue')\
\nr2=Rect(200,200, 400, 50, 90, None, 'red')\
\nc1=Circle(200,200, 200,0, None, 'green',360)\
\nc2=Circle(200,200, 100, 45, None, 'brown',270)\
\no1=Oval(200,200, 200, 400, 45, None, 'blue')\
\no2=Oval(200,200, 200, 400, -45, None, 'purple')\
\np1=Polygon(200,200, 100, 5, 0 , 'orange', 'blue')\
\np2=Polygon(200,200, 150, 9, 0 , None, 'green')\
\ns1=Star(200, 200, 25,5, 0.2, 0, 'yellow', 'red') \
\ns2=Star(200, 200, 200, 20, 0.4 ,0, None, 'purple')\
\n\
\n#variables to use in update\
\ndatabase.r1Up = True\
\ndatabase.r2Right = True\
\ndatabase.c2RadUp = False\
\ndatabase.p2EdgesUp = False\
\ndatabase.s2PointsUp= False\
\n\
\ndef OnFrameUpdate():\
\n    # code to move r1\
\n    if r1.y + r1.height/2 >= 400:\
\n        database.r1Up = False\
\n    if r1.y - r1.height/2 <= 0:\
\n        database.r1Up = True\
\n    \
\n    if database.r1Up:\
\n        r1.y+=5\
\n    else:\
\n        r1.y-=5\
\n    \
\n    #code to move r2\
\n    if r2.x + r2.height/2 >= 400:\
\n        database.r2Right = False\
\n    if r2.x - r2.height/2 <= 0:\
\n        database.r2Right = True\
\n    if database.r2Right:\
\n        r2.x+=5\
\n    else:\
\n        r2.x-=5\
\n\
\n    # code to change c2\
\n    if c2.radius >= 200:\
\n       database.c2RadUp = False\
\n    if c2.radius <= 5:\
\n        database.c2RadUp = True\
\n    if database.c2RadUp:\
\n        c2.radius+=3\
\n    else:\
\n        c2.radius-=3\
\n    \
\n    # code to rotate oval1 and oval 2\
\n    o1.rotation+=1\
\n    o2.rotation-=1\
\n    \
\n    # code to change edges on polygon 2\
\n    if p2.edges >= 15:\
\n        database.p2EdgesUp = False\
\n    if p2.edges < 3:\
\n        database.p2EdgesUp = True\
\n    if database.p2EdgesUp:\
\n        p2.edges+=1\
\n    else:\
\n        p2.edges-=1\
\n    \
\n    #code to change points on star2\
\n    if s2.points >= 50:\
\n        database.s2PointsUp = False\
\n    if s2.points <= 3:\
\n        database.s2PointsUp = True\
\n    if database.s2PointsUp:\
\n        s2.points+=1\
\n    else:\
\n        s2.points-=1\
\n    \
\n    # code to rotate p1 and s1\
\n    p1.rotation-=1\
\n    s1.rotation+=1"
export const tictactoe = "#Label\
\ninstructions = Label('Click To Start',200,145,10, 'orange', 'Times New Roman','bold')\
\nwinLabel = Label('', 200,245,10, 'green', 'Times New Roman','bold')\
\n\
\nturnLabel = Label('',45,390,10, 'blue', 'Times New Roman','bold')\
\n\
\n\
\n# build the grid\
\ngridh1=Rect(200,135, 400, 10,0,'black')\
\ngridh2=Rect(200,265, 400, 10,0,'black')\
\ngridv1=Rect(135,200, 10, 400,0,'black')\
\ngridv2=Rect(265,200, 10, 400,0,'black')\
\n\
\ndatabase.isPlayer1Turn = True\
\ndatabase.gameState = 1\
\ndatabase.p1shapes = []\
\ndatabase.p2shapes = []\
\ndatabase.pturn = 1\
\ndatabase.spotTaken = [[0,0,0],[0,0,0],[0,0,0]]\
\ndatabase.isPlayer1X = True\
\ndatabase.isPlayer1Blue = True\
\n\
\ndef ClearSpots(spotTaken, p1shapes, p2shapes):\
\n    for i in range(3):\
\n        for j in range(3):\
\n            spotTaken[i][j] = 0\
\n    for shape in p1shapes:\
\n        shape.Clear()\
\n    for shape in p2shapes:\
\n        shape.Clear()\
\ndef Findij(x,y):\
\n    if x > 0 and x < 135 and y > 265 and y < 400:\
\n        i = 0\
\n        j = 0\
\n        x = 65\
\n        y = 330\
\n    if x > 135 and x < 265 and y > 265 and y < 400:\
\n        i = 0\
\n        j = 1\
\n        x = 200\
\n        y = 330\
\n    if x > 265 and x < 400 and y > 265 and y < 400:\
\n        i = 0\
\n        j = 2\
\n        x = 330\
\n        y = 330\
\n    if x > 0 and x < 135 and y > 135 and y < 265:\
\n        i = 1\
\n        j = 0\
\n        x = 65\
\n        y = 200\
\n    if x > 135 and x < 265 and y > 135 and y < 265:\
\n        i = 1\
\n        j = 1\
\n        x = 200\
\n        y = 200\
\n    if x > 265 and x < 400 and y > 135 and y < 265:\
\n        i = 1\
\n        j = 2\
\n        x = 330\
\n        y = 200\
\n    if x > 0 and x < 135 and y > 0 and y < 135: #done\
\n        i = 2\
\n        j = 0\
\n        x = 65\
\n        y = 65\
\n    if x > 135 and x < 265 and y > 0 and y < 135:\
\n        i = 2\
\n        j = 1\
\n        x = 200\
\n        y = 65\
\n    if x > 265 and x < 400 and y > 0 and y < 135:\
\n        i = 2\
\n        j = 2\
\n        x = 330\
\n        y = 65\
\n    return [i,j,x,y]\
\n\
\ndef drawPiece(x,y,shape,color, plist):   \
\n    if shape=='x':\
\n        plist.append(Rect(x,y, 75, 20, 45, color, None))\
\n        plist.append(Rect(x,y, 75, 20, -45, color, None))\
\n    else:\
\n        plist.append(Circle(x,y, 40, 0, color, None,360))\
\n        plist.append(Circle(x,y, 20, 0, 'white', None,360))\
\n\
\n        \
\ndef CheckFull(spotTaken):\
\n    for row in spotTaken:\
\n        for e in row:\
\n            if e == 0:\
\n                return False;\
\n    return True\
\ndef CheckWin(spotTaken):\
\n    #check rows and columns\
\n    for i in range(3):\
\n        if (spotTaken[i][0] != 0 and spotTaken[i][1] != 0 and spotTaken[i][2] != 0):\
\n            if (spotTaken[i][0] == spotTaken[i][1] and spotTaken[i][1] == spotTaken[i][2]):\
\n                if spotTaken[i][0] == 1:\
\n                    return 1\
\n                else:\
\n                    return 2\
\n        if (spotTaken[0][i] !=0 and spotTaken[1][i] !=0 and spotTaken[2][i] != 0):\
\n            if (spotTaken[0][i] == spotTaken[1][i] and spotTaken[1][i] == spotTaken[2][i]):\
\n                if spotTaken[0][i] == 1:\
\n                    return 1\
\n                else:\
\n                    return 2\
\n    #check diagonal 1\
\n    if (spotTaken[0][0] != 0 and spotTaken[1][1] != 0 and spotTaken[2][2] != 0):    \
\n        if (spotTaken[0][0] == spotTaken[1][1] and spotTaken[1][1] == spotTaken[2][2]):\
\n            if spotTaken[1][1] == 1:\
\n                return 1\
\n            else:\
\n                return 2\
\n    #check diagonal 2\
\n    if (spotTaken[2][0] !=0 and spotTaken[1][1] != 0 and spotTaken[0][2] != 0):\
\n        if (spotTaken[2][0] == spotTaken[1][1] and spotTaken[1][1] == spotTaken[0][2]):\
\n            if spotTaken[1][1] == 1:\
\n                return 1\
\n            else:\
\n                return 2\
\n    return 0\
\n    \
\n\
\ndef OnClick(x,y):\
\n    # game over state\
\n    if database.gameState == 0:\
\n        instructions.text = 'Click To Start'\
\n        database.pturn = 1\
\n        winLabel.text = ''\
\n        database.gameState = 1\
\n    if database.gameState == 3:\
\n        [i,j,cx,cy] = Findij(x,y)\
\n        if database.spotTaken[i][j] == 0:\
\n            if database.pturn == 1:\
\n                drawPiece(cx,cy,'x' if database.isPlayer1X else 'o','blue' if database.isPlayer1Blue else 'red', database.p1shapes)\
\n                database.pturn = 2\
\n                database.spotTaken[i][j] = 1\
\n                turnLabel.text=  ' Player 2: ' + ('O' if database.isPlayer1X else 'X')\
\n                turnLabel.fillColor = 'red' if database.isPlayer1Blue else 'blue'\
\n\
\n            else:\
\n                drawPiece(cx,cy,'o' if database.isPlayer1X else 'x','red' if database.isPlayer1Blue else 'blue', database.p2shapes)\
\n                database.pturn = 1\
\n                database.spotTaken[i][j] = 2\
\n                turnLabel.text=' Player 1: ' + ('X' if database.isPlayer1X else 'O')\
\n                turnLabel.fillColor = 'blue' if database.isPlayer1Blue else 'red'\
\n\
\n\
\n        winner = CheckWin(database.spotTaken)\
\n        if winner != 0:\
\n            database.gameState = 0\
\n            instructions.text = 'Click To Play Again'\
\n            if winner == 1:\
\n                winLabel.fillColor = 'blue' if database.isPlayer1Blue else 'red'\
\n                winLabel.text = 'Player 1 Wins'\
\n            if winner == 2:\
\n                winLabel.fillColor = 'red' if database.isPlayer1Blue else 'blue'\
\n                winLabel.text = 'Player 2 Wins'\
\n            \
\n        if CheckFull(database.spotTaken) and database.gameState != 0:\
\n            database.gameState = 0\
\n            winLabel.fillColor = 'green'\
\n            winLabel.text = 'Draw'\
\n            instructions.text = 'Click To Play Again'\
\n        if database.gameState == 0:\
\n            turnLabel.text = ''\
\n        \
\n    if database.gameState == 1:\
\n        ClearSpots(database.spotTaken,database.p1shapes,database.p2shapes)\
\n        drawPiece(65,330,'x','blue', database.p1shapes)\
\n        drawPiece(330,330,'o','red', database.p2shapes)\
\n        drawPiece(65,65,'x','red', database.p1shapes)\
\n        drawPiece(330,65,'o','blue', database.p2shapes)\
\n        instructions.text='P1: Pick X or O'\
\n        #user picks a piece \
\n        [i,j,cx,cy] = Findij(x,y)\
\n        if i==0 and j == 0:\
\n            database.isPlayer1X = True\
\n            database.isPlayer1Blue = True\
\n            database.gameState = 2\
\n        if i==0 and j == 2:\
\n            database.isPlayer1X = False\
\n            database.isPlayer1Blue = False\
\n            database.gameState = 2\
\n        if i==2 and j == 0:\
\n            database.isPlayer1X = True\
\n            database.isPlayer1Blue = False\
\n            database.gameState = 2\
\n        if i==2 and j == 2:\
\n            database.isPlayer1X = False\
\n            database.isPlayer1Blue = True\
\n            database.gameState = 2\
\n    if database.gameState == 2:\
\n        instructions.text = ''\
\n        database.gameState = 3\
\n        turnLabel.text=' Player 1: ' + ('X' if database.isPlayer1X else 'O')\
\n        turnLabel.fillColor = 'blue' if database.isPlayer1Blue else 'red'\
\n        database.pturn = 1\
\n        ClearSpots(database.spotTaken, database.p1shapes, database.p2shapes)"
export const onePlayerPong = "# 1-Player Pong\
\n\
\ninstructions=Label('Drag the paddle & Win if you pass level 10',200,385,10,'black','Times New Roman','normal')\
\npaddle = Rect(200,10,84,15,0,'blue')\
\nball = Circle(205,35, 7,0, 'red', 360)\
\nlevelLabel = Label('Level: 1',30,385,10, 'green', 'Times New Roman','bold')\
\nendLabel = Label('',200,200,24, 'red', 'Times New Roman','bold')\
\n\
\ndatabase.gameState = -1 # 0-Playing 1-Lost 2-Won\
\ndatabase.level=1\
\ndatabase.ballHeading = (0,1)\
\ndatabase.hitCount=0\
\n\
\ndef OnClick(mouseX,mouseY):\
\n    paddle.x = mouseX\
\n    if database.gameState == -1:\
\n        database.gameState = 0\
\n    if database.gameState == 1 or database.gameState ==2:\
\n        database.gameState = -1\
\ndef OnDrag(mouseX,mouseY):\
\n    paddle.x = mouseX\
\n\
\ndef OnFrameUpdate():\
\n    \
\n    if database.gameState == -1:\
\n        instructions.text = 'Drag the paddle & Win if you pass level 10'\
\n        endLabel.text = ''\
\n        paddle.x = 200\
\n        paddle.y = 10\
\n        ball.x = 205\
\n        ball.y = 35\
\n        database.level=1\
\n        database.ballHeading = (0,1)\
\n        database.hitCount=0\
\n    if database.gameState == 0:\
\n        if ball.IsTouching('top-wall'):\
\n            ball.y=400-ball.radius\
\n            database.ballHeading = (database.ballHeading[0],-database.ballHeading[1])\
\n        if ball.IsTouching('right-wall'):\
\n            ball.x=400-ball.radius\
\n            database.ballHeading = (-database.ballHeading[0],database.ballHeading[1])\
\n        if ball.IsTouching('left-wall'):\
\n            ball.x=ball.radius    \
\n            database.ballHeading = (-database.ballHeading[0],database.ballHeading[1])\
\n        if ball.IsTouching(paddle):\
\n            ball.y=paddle.y+paddle.height/2+ball.radius\
\n            #using percentage and shifting between -1 and 1\
\n            perc = (((ball.x - paddle.x + paddle.width/2)/paddle.width) - 0.5)*2\
\n            #use perc as x value in ball Heading\
\n            database.ballHeading = (perc/((perc**2+1)**0.5),1/((perc**2+1)**0.5))\
\n            database.hitCount += 1\
\n            if database.hitCount > 2:\
\n                database.hitCount = 0\
\n                database.level+=1\
\n                levelLabel.text=f'Level: {database.level}'\
\n                if database.level == 2:\
\n                    instructions.text=''\
\n                if database.level == 11:\
\n                    database.gameState = 2 \
\n        else:\
\n            if ball.IsTouching('bottom-wall'):\
\n                database.gameState = 1\
\n        \
\n        ballSpeed = database.level*2+2    \
\n        ball.x += ballSpeed*database.ballHeading[0]\
\n        ball.y += ballSpeed*database.ballHeading[1]\
\n\
\n    elif database.gameState == 1:\
\n        endLabel.text = 'Game Over'\
\n        endLabel.color = 'red'\
\n        instructions.text ='Click To Reset'\
\n    elif database.gameState == 2:\
\n        endLabel.text = 'You Win'\
\n        endLabel.color = 'blue'\
\n        instructions.text ='Click To Reset'\
\n\
\n    "
