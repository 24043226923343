import React, { useState } from "react";
import AceEditor from "react-ace";
import Select, { NonceProvider } from "react-select";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import Toggle from "react-toggle";
import "../../assets/css/react-toggle.css";

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  registerables,
} from "chart.js";
import { Scatter } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  Dropdown,
  FormGroup,
  Form,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupButtonDropdown,
  ButtonDropdown,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DropdownWhiteNavbar from "components/Navbars/DropdownWhiteNavbar.js";
import ContactUsHeader from "components/Headers/ContactUsHeader.js";
import Footer from "components/Footers/Footer.js";


import { lessons } from "./lessons.js";
ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 400,
      },
    },
    tooltip: {
      title: "(x,y)",
      bodyColor: "#fff",
      displayColors: false,
    },
  },
};

const a = [0, 50, 100, 150, 200, 250, 300, 350, 400];
const b = [];
for (var i = 0; i < a.length; i++) {
  for (var j = 0; j < a.length; j++) {
    b.push({ x: a[i], y: a[j] });
  }
}

export const data = {
  datasets: [
    {
      data: b,
      backgroundColor: "rgba(255, 99, 132, 1)",
      pointRadius: 5,
      pointHoverRadius: 3,
      pointHoverBackgroundColor: "black",
      borderWidth: 0,
      backgroundColor: "transparent",
    },
  ],
};

function Home() {
  const [showGrid, setShowGrid] = useState(false);
  const [runToggle, setRunToggle] = useState(false);
  const [wait, setWait] = useState(false);
  function RunCode() {
    setWait(true);
    window.runit();
    setTimeout(function() {
      setWait(false);
    }, 100); // 0.1 second delay
  }
  function ClearGraphics() {
    setWait(true);
    window.stopit();
    const myNode = document.getElementById("mycanvas");
    setTimeout(function() {
      myNode.innerHTML = "";
      setWait(false);
    }, 100); // 0.1 second delay
  }
  function ClearTextOutput() {
    const outputNode = document.getElementById("output");
    outputNode.innerHTML = "";
  }

  const [code, setCode] = React.useState();
  const [lesson, setLesson] = React.useState(lessons[0]);

  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    setCode(lessons[0].code);
    setLesson(lessons[0]);

    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  React.useEffect(() => {
    setCode(lesson.code);
  }, [lesson]);

  return (
    <>
      <DropdownWhiteNavbar />
      <div className="wrapper">
        <div className="section">
          <Container>
            <h3 style={{ textAlign: "center" }}>Beginning Python</h3>
            <Row>
              <Col xs={11} md={5} lg={6} xl={7}>
                <div style={{ paddingLeft: "50px" }}>
                  <Select
                    className="react-select react-select-primary mt-2"
                    onChange={(option) => {
                      setLesson(option);
                    }}
                    classNamePrefix="react-select"
                    placeholder="Examples and Lessons"
                    value={lesson}
                    options={lessons}
                  ></Select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={11} md={5} lg={6} xl={7}>
                <Label>Code</Label>
                <AceEditor
                  mode="python"
                  theme="tomorrow"
                  onChange={setCode}
                  name="UNIQUE_ID_OF_DIV"
                  editorProps={{
                    $blockScrolling: true,
                    $background: "red!important",
                  }}
                  setOptions={{
                    firstLineNumber: 2,
                  }}
                  style={{
                    height: "400px",
                    marginBottom: "30px",
                    width: "100%",
                    border: "solid 2px #999",
                    backgroundColor: "#fafafa",
                  }}
                  value={code}
                />

                <Input
                  type="textarea"
                  id="code"
                  style={{ display: "none" }}
                  onChange={() => {}}
                  value={
                    "import time;import turtle;from custommodule import Prefix,RunAfter,Camera,Rect,Circle,Oval,Polygon,Star,Label,Button,_dragpen,database;Prefix()" +
                    "\n" +
                    code +
                    "\ntry:" +
                    "\n\tOnClick=RunAfter(OnClick)" +
                    "\n\tturtle.Screen().onclick(OnClick)\n" +
                    "except:" +
                    "\n\tpass" +
                    "\ntry:" +
                    "\n\tOnKey=RunAfter(OnKey)" +
                    "\n\tfor n in range(48,123):" +
                    "\n\t\tturtle.Screen().onkey(lambda n=n: OnKey(chr(n)), chr(n))" +
                    "\n\tkeycode={'%': 'left-arrow', '&': 'up-arrow', '(': 'down-arrow', \"'\": 'right-arrow', chr(32): 'space', chr(8): 'backspace', chr(13): 'enter', chr(27): 'escape'}" +
                    "\n\tfor n in keycode:"+
                    "\n\t\tturtle.Screen().onkey(lambda n=n: OnKey(keycode[n]), n)" +
                    "\nexcept:" +
                    "\n\tpass" +
                    "\ntry:" +
                    "\n\tOnDrag=RunAfter(OnDrag)" +
                    "\n\t_dragpen.ondrag(OnDrag)" +
                    "\nexcept:" +
                    "\n\tpass" +
                    "\ntry:" +
                    "\n\tturtle.Screen().listen()" +
                    "\nexcept:" +
                    "\n\tpass" +
                    "\ntry:" +
                    "\n\tturtle.Screen().mainloop()" +
                    "\n\tpass" +
                    "\nexcept:" +
                    "\n\tprint('mainloop error')" +
                    "\ntry:" +
                    "\n\tfps=Label(f'fps: 0',380,390,8,'black')" +
                    "\n\tcount=0" +
                    "\n\tfps_acc=0" +
                    "\n\twhile True:" +
                    "\n\t\tstart=time.time()" +
                    "\n\t\tOnFrameUpdate()" +
                    "\n\t\tturtle.Screen().update()" +
                    "\n\t\ttime.sleep(1.7976931348623157e+308)" +
                    "\n\t\tend=time.time()" +
                    "\n\t\tfps_acc+=(end-start)" +
                    "\n\t\tcount+=1" +
                    "\n\t\tif fps_acc >= 0.33:" +
                    "\n\t\t\tfps.text=f'fps: {count*3}'" +
                    "\n\t\t\tcount=0" +
                    "\n\t\t\tfps_acc=0" +
                    "\nexcept:" +
                    "\n\tpass"
                  }
                />
                <Input
                  type="textarea"
                  id="custom-module"
                  style={{ display: "none" }}
                  onChange={() => {}}
                  value={lesson.module}
                />

                <div style={{ display: "inline-block", width: "100%" }}>
                  {runToggle ? (
                    <Button
                      color="danger"
                      style={{ float: "left" }}
                      onClick={()=>(ClearGraphics(),setRunToggle(!runToggle))}
                      disabled={wait}
                    >
                      {wait ? "Wait...": "Stop"}
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      style={{ float: "left" }}
                      onClick={()=>(RunCode(),setRunToggle(!runToggle))}
                      disabled={wait}
                    >
                      {wait ? "Wait...": "Run"}
                    </Button>
                  )}

                  <Button
                    color="link"
                    disabled
                    style={{
                      float: "right",
                      color: "black",
                      fontSize: "medium",
                    }}
                  >
                    Examples:{" "}
                  </Button>
                </div>
              </Col>
              <Col xs={8} md={7} lg={5} xl={5}>
                <label style={{ height: "10px" }}>
                  <Label style={{ textAlign: "center", marginLeft: "30px" }}>
                    Graphics
                  </Label>
                  <Label style={{ marginLeft: "75px", marginRight: "15px" }}>
                    Show Grid
                  </Label>
                  <Toggle
                    defaultChecked={showGrid}
                    onChange={() => setShowGrid(!showGrid)}
                  />
                </label>

                <div
                  id="canvasgridcontainer"
                  style={{
                    width: "460px",
                    height: "430px",
                    position: "relative",
                  }}
                >
                  <div
                    id="mycanvas"
                    style={{
                      border: "solid 2px #999",
                      width: "404px",
                      height: "404px",
                      position: "absolute",
                      top: "0px",
                      left: "30px",
                    }}
                  ></div>
                  {showGrid && (
                    <div
                      id="grid"
                      style={{
                        width: "446px",
                        height: "435px",
                        position: "absolute",
                        top: "-8px",
                        left: "-2px",
                        zIndex: "0",
                        pointerEvents: "visiblePoint",
                      }}
                    >
                      <Scatter options={options} data={data} key={showGrid} />
                    </div>
                  )}
                </div>
                {/* 
                <Button
                  style={{ marginLeft: "45px" }}
                  color="primary"
                  onClick={ClearGraphics}
                >
                  Clear
                </Button>
                */}
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Label>Code Output</Label>
                <pre
                  id="output"
                  style={{
                    width: "85%",
                    minHeight: "100px",
                    maxHeight: "100px",
                    border: "solid 2px #999",
                  }}
                ></pre>
                <Button
                  color="primary"
                  style={{ float: "left" }}
                  onClick={ClearTextOutput}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
