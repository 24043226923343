import React, { useState } from "react";
import DropdownWhiteNavbar from "components/Navbars/DropdownWhiteNavbar";
import Footer from "components/Footers/Footer";

const ShapeDocumentation = () => {
  const [section, setSection] = useState("rectangles");
  const [isShapesOpen, setIsShapesOpen] = useState(true);
  const [isShapeMethodsOpen, setIsShapeMethodsOpen] = useState(true);
  const [isEventsOpen, setIsEventsOpen] = useState(true);
  const [isDatabaseOpen, setIsDatabaseOpen] = useState(true);

  const toggleShapes = () => setIsShapesOpen(!isShapesOpen);
  const toggleShapeMethods = () => setIsShapeMethodsOpen(!isShapeMethodsOpen);
  const toggleEvents = () => setIsEventsOpen(!isEventsOpen);
  const toggleDatabase = () => setIsDatabaseOpen(!isDatabaseOpen);

  const navStyle = {
    width: '200px', // Fixed width for the left menu
    height: 'auto',
    border: '2px solid black',
    borderRadius: '15px',
    padding: '10px',
    margin: '25px 25px 0 25px',
  };
  

  const ulStyle = {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  };

  const liStyle = {
    padding: '5px 0',
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
    cursor: 'pointer',
  };

  const headerStyle = {
    fontWeight: 'bold',
    margin: '10px 0',
    padding: '5px',
    border: '1px solid black',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: '#f0f0f0',
  };

  const renderSection = () => {
    switch (section) {
      case "rectangles":
        return (
          <div>
            <h3>Rectangles</h3>
            <pre>
              <code>
                {`class Rect:
    """
    Creates a rectangle object.

    Parameters:
    - x, y: int or float - Coordinates of the center of the rectangle.
    - width, height: int or float - Width and height of the rectangle.
    - rotation: int or float, optional - Rotation angle in degrees (clockwise).
    - fillColor: str, optional - Fill color of the rectangle.
    - borderColor: str, optional - Border color of the rectangle.
    """`}
              </code>
            </pre>
            <strong>Example usage:</strong>
            <pre>
              <code>
                {`r1 = Rect(200, 200, 400, 50, 0, None, 'blue')
r2 = Rect(200, 200, 400, 50, 90, None, 'red')`}
              </code>
            </pre>
          </div>
        );
      case "circles":
        return (
          <div>
            <h3>Circles</h3>
            <pre>
              <code>
                {`class Circle:
    """
    Creates a circle object.

    Parameters:
    - x, y: int or float - Coordinates of the center of the circle.
    - radius: int or float - Radius of the circle.
    - rotation: int or float, optional - Rotation angle in degrees (clockwise).
    - fillColor: str, optional - Fill color of the circle.
    - borderColor: str, optional - Border color of the circle.
    - degrees: int or float, optional - Arc angle in degrees (default is 360 for a full circle).
    """`}
              </code>
            </pre>
            <strong>Example usage:</strong>
            <pre>
              <code>
                {`c1 = Circle(200, 200, 200, 0, None, 'green', 360)
c2 = Circle(200, 200, 100, 45, None, 'brown', 270)`}
              </code>
            </pre>
          </div>
        );
      case "ovals":
        return (
          <div>
            <h3>Ovals</h3>
            <pre>
              <code>
                {`class Oval:
    """
    Creates an oval object.

    Parameters:
    - x, y: int or float - Coordinates of the center of the oval.
    - width, height: int or float - Width and height of the oval.
    - rotation: int or float, optional - Rotation angle in degrees (clockwise).
    - fillColor: str, optional - Fill color of the oval.
    - borderColor: str, optional - Border color of the oval.
    """`}
              </code>
            </pre>
            <strong>Example usage:</strong>
            <pre>
              <code>
                {`o1 = Oval(200, 200, 200, 400, 45, None, 'blue')
o2 = Oval(200, 200, 200, 400, -45, None, 'purple')`}
              </code>
            </pre>
          </div>
        );
      case "polygons":
        return (
          <div>
            <h3>Polygons</h3>
            <pre>
              <code>
                {`class Polygon:
    """
    Creates a polygon object.

    Parameters:
    - x, y: int or float - Coordinates of the center of the polygon.
    - radius: int or float - Radius of the polygon's circumscribed circle.
    - sides: int - Number of sides of the polygon.
    - rotation: int or float, optional - Rotation angle in degrees (clockwise).
    - fillColor: str, optional - Fill color of the polygon.
    - borderColor: str, optional - Border color of the polygon.
    - degrees: int or float, optional - Arc angle in degrees (default is 360 for a full polygon).
    """`}
              </code>
            </pre>
            <strong>Example usage:</strong>
            <pre>
              <code>
                {`p1 = Polygon(200, 200, 100, 5, 0, 'orange', 'blue')
p2 = Polygon(200, 200, 150, 9, 0, None, 'green')`}
              </code>
            </pre>
          </div>
        );
      case "stars":
        return (
          <div>
            <h3>Stars</h3>
            <pre>
              <code>
                {`class Star:
    """
    Creates a star object.

    Parameters:
    - x, y: int or float - Coordinates of the center of the star.
    - radius: int or float - Radius of the circumscribed circle of the star.
    - points: int - Number of points/tips of the star.
    - sharpness: float - Sharpness of the star's points (0 to 1).
    - rotation: int or float, optional - Rotation angle in degrees (clockwise).
    - fillColor: str, optional - Fill color of the star.
    - borderColor: str, optional - Border color of the star.
    """`}
              </code>
            </pre>
            <strong>Example usage:</strong>
            <pre>
              <code>
                {`s1 = Star(200, 200, 25, 5, 0.2, 0, 'yellow', 'red')
s2 = Star(200, 200, 200, 20, 0.4, 0, None, 'purple')`}
              </code>
            </pre>
          </div>
        );
      case "IsTouching":
        return (
          <div>
            <h3>Shape.IsTouching()</h3>
            <pre>
              <code>
                {`# All shapes have the following method.
Shape.IsTouching(Shape)
Shape.IsTouching(wall)
"""
    Return:
          True if there is a collision with the parameter (wall or Shape)
          
    Parameters:
    - Shape: another Shape Object
    - wall: wall string
          - 'left-wall'
          - 'right-wall'
          - 'top-wall'
          - 'bottom-wall'        
Example Usage:
r1=Rect(200,200,50,50,0,'blue')
c2=Circle(100,100, 25,0, 'red', 360)
if r1.IsTouching(c2):
  # r1 is touching c2
  # do something
else:
  # r1 is not touching c2
  # do something

if c2.IsTouching('left-wall'):
  # c2 is touching the left wall
  # do something
else:
  # c2 is not touching the left wall
  # do something
`}
              </code>
            </pre>
          </div>
        );
      case "OnClick":
        return (
          <div>
            <h3>OnClick</h3>
            <pre>
              <code>
                {`def OnClick(mouseX, mouseY):
    """
    Use the position of the mouse in this function.

    Parameters:
    - mouseX: int or float - X coordinate of the mouse click.
    - mouseY: int or float - Y coordinate of the mouse click.
    
    Example Usage:
    - update a rectangle's center position to the mouse position
    r1.x = mouseX
    r1.y = mouseY
    """
`}
              </code>
            </pre>
          </div>
        );
      case "OnKey":
        return (
          <div>
            <h3>OnKey</h3>
            <pre>
              <code>
                {`def OnKey(key):
    """
    Detect a Key press. All keys work and full documentation coming soon.

    Parameters:
    - key: str - Key that was pressed (e.g., 'w', 'a', 's', 'd').
    """
    if key == 'w':
          # move shape up
    elif key == 'a':
          # move shape left
    elif key == 's':
          # move shape down
    elif key == 'd':
          # move shape right
          `}
              </code>
            </pre>
          </div>
        );
      case "OnDrag":
        return (
          <div>
            <h3>OnDrag</h3>
            <pre>
              <code>
                {`def OnDrag(mouseX, mouseY):
    """
    Detect if the mouse button is held while the mouse is moving.

    Parameters:
    - mouseX: int or float - X coordinate of the mouse drag.
    - mouseY: int or float - Y coordinate of the mouse drag.
    
    Example Useage update a shapes center to the position of the mouse
    - drag an item
    r1.x = mouseX
    r1.y = mouseY
    """
    `}
              </code>
            </pre>
          </div>
        );
      case "OnFrameUpdate":
        return (
          <div>
            <h3>OnFrameUpdate</h3>
            <pre>
              <code>
                {`def OnFrameUpdate():
    """
    This function updates as many times as possible and runs continuously.
    If this function runs 60 times per second, you get 60 frames per second (fps)
    
    Example usage:
    - Updates the color of rectangle r1 depending on if it is touching circle c2.

    if r1.IsTouching(c2):
        r1.fillColor = 'red'
    else:
        r1.fillColor = 'blue'
    """"
        `}
              </code>
            </pre>
          </div>
        );
      case "Database Obj":
        return (
          <div>
            <h3>Database Object</h3>
            <pre>
              <code>
                {`# The database is a built-in object that allows users to store any data type as a variable.
# You can retrieve values or change values.

database.name = 'chris'
database.id = 12
database.gpa = 3.7
database.grades = [98.3, 67.8, 77.2, 50.7]

# Show all values in the database
database.show()  # or print(database)

# Modify the gpa variable
database.gpa = 4.0

# Access grades and create a new variable called average
database.average = sum(database.grades) / len(database.grades)

# Show all values to see the database change
print(database)

# Database values can be accessed anywhere in your code
# Example of accessing a database value in a function
def PrintStuff(something):
    print(something, database.name)

PrintStuff('hello')`}
              </code>
            </pre>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <DropdownWhiteNavbar />
      <div style={{ margin: "50px", paddingTop: "25px" }}>
        <div style={{ display: "flex" }}>
          <nav id="related_links" style={navStyle}>
            <ul style={ulStyle}>
              <li style={liStyle}>
                <div style={headerStyle} onClick={toggleShapes}>
                  Shapes
                </div>
                {isShapesOpen && (
                  <ul>
                    <li style={liStyle}>
                      <a
                        href="#"
                        style={linkStyle}
                        onClick={() => setSection("rectangles")}
                      >
                        Rectangles
                      </a>
                    </li>
                    <li style={liStyle}>
                      <a
                        href="#"
                        style={linkStyle}
                        onClick={() => setSection("circles")}
                      >
                        Circles
                      </a>
                    </li>
                    <li style={liStyle}>
                      <a
                        href="#"
                        style={linkStyle}
                        onClick={() => setSection("ovals")}
                      >
                        Ovals
                      </a>
                    </li>
                    <li style={liStyle}>
                      <a
                        href="#"
                        style={linkStyle}
                        onClick={() => setSection("polygons")}
                      >
                        Polygons
                      </a>
                    </li>
                    <li style={liStyle}>
                      <a
                        href="#"
                        style={linkStyle}
                        onClick={() => setSection("stars")}
                      >
                        Stars
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li style={liStyle}>
                <div style={headerStyle} onClick={toggleShapeMethods}>
                  Shape Methods
                </div>
                {isShapeMethodsOpen && (
                  <ul>
                    <li style={liStyle}>
                      <a
                        href="#"
                        style={linkStyle}
                        onClick={() => setSection("IsTouching")}
                      >
                        IsTouching()
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li style={liStyle}>
                <div style={headerStyle} onClick={toggleEvents}>
                  Events
                </div>
                {isEventsOpen && (
                  <ul>
                    <li style={liStyle}>
                      <a
                        href="#"
                        style={linkStyle}
                        onClick={() => setSection("OnClick")}
                      >
                        OnClick
                      </a>
                    </li>
                    <li style={liStyle}>
                      <a
                        href="#"
                        style={linkStyle}
                        onClick={() => setSection("OnKey")}
                      >
                        OnKey
                      </a>
                    </li>
                    <li style={liStyle}>
                      <a
                        href="#"
                        style={linkStyle}
                        onClick={() => setSection("OnDrag")}
                      >
                        OnDrag
                      </a>
                    </li>
                    <li style={liStyle}>
                      <a
                        href="#"
                        style={linkStyle}
                        onClick={() => setSection("OnFrameUpdate")}
                      >
                        OnFrameUpdate
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li style={liStyle}>
                <div style={headerStyle} onClick={toggleDatabase}>
                  Database
                </div>
                {isDatabaseOpen && (
                  <ul>
                    <li style={liStyle}>
                      <a
                        href="#"
                        style={linkStyle}
                        onClick={() => setSection("Database Obj")}
                      >
                        Database Obj
                      </a>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </nav>
          <main style={{ flex: 1 }}>{renderSection()}</main>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ShapeDocumentation;

