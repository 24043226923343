import React from "react";
import AceEditor from "react-ace";
import Select from "react-select";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

// reactstrap components
import {
  Button,
  Dropdown,
  FormGroup,
  Form,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupButtonDropdown,
  ButtonDropdown,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DropdownWhiteNavbar from "components/Navbars/DropdownWhiteNavbar.js";
import WhiteNavbar from "components/Navbars/WhiteNavbar.js";
import ContactUsHeader from "components/Headers/ContactUsHeader.js";
import Footer from "components/Footers/Footer.js";

import { lessons } from "./lessons.js";

function Playground() {
  function RunCode() {
    const myNode = document.getElementById("mycanvas");
    myNode.innerHTML = "";
    window.runit();
  }
  function ClearGraphics() {
    const myNode = document.getElementById("mycanvas");
    myNode.innerHTML = "";
  }

  const [code, setCode] = React.useState();
  const [lesson, setLesson] = React.useState(lessons[0]);

  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    setCode(lessons[0].code);
    setLesson(lessons[0]);

    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  React.useEffect(() =>{
    setCode(lesson.code)
  },[lesson])

  return (
    <>
      {/*<DropdownWhiteNavbar />*/}
      <WhiteNavbar/>
      <div className="wrapper">
        <div className="section">
          <Container>
            <h3 style={{ textAlign: "center" }}>Beginning Python</h3>
            <Row>
            <Col xs={11} md={5} lg={6} xl={7}>
            <div style={{paddingLeft: "50px"}}>
            <Select
                      className="react-select react-select-primary mt-2"
                      onChange={(option) => {setLesson(option)}}
                      classNamePrefix="react-select"
                      placeholder="Examples and Lessons"
                      value={lesson}
                      options={lessons}
                    ></Select>
                    </div>
            </Col>
            </Row>
            <Row>
              <Col xs={11} md={5} lg={6} xl={7}>
                <Label>Code</Label>
                <AceEditor
                  mode="python"
                  theme="tomorrow"
                  onChange={setCode}
                  name="UNIQUE_ID_OF_DIV"
                  editorProps={{ $blockScrolling: true, $background: "red!important"}}
                  setOptions={{
                    firstLineNumber: 3,
                  }}
                  style={{
                    height: "404px",
                    width: "100%",
                    border: "solid 2px #999",
                    backgroundColor: "#fafafa"
                  }}
                  value={code}
                />

                <Input type="textarea" id="code" style={{display: "none"}} onChange={()=>{}}  value={'from prefix import prefix,Rect,Circle,Oval,Polygon,Star\nprefix()\n' + code}/>
                <Input type="textarea" id="prefix" style={{display: "none"}} onChange={()=>{}} value={lesson.prefix}/>

                <div style={{ display: "inline-block", width: "100%" }}>
                  <Button
                    color="primary"
                    style={{ float: "left" }}
                    onClick={RunCode}
                  >
                    Run
                  </Button>

                  <Button
                    color="link"
                    disabled
                    style={{
                      float: "right",
                      color: "black",
                      fontSize: "medium",
                    }}
                  >
                    Examples:{" "}
                  </Button>
                </div>
              </Col>
              <Col xs={4} md={4} lg={4} xl={4}>
                <Label style={{ textAlign: "center" }}>Graphics</Label>
                <div
                  id="mycanvas"
                  style={{
                    border: "solid 2px #999",
                    width: "404px",
                    height: "404px",
                  }}
                ></div>
                <Button color="primary" onClick={ClearGraphics}>
                  Clear
                </Button>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col>
                <Label>Code Output</Label>
                <pre
                  id="output"
                  style={{
                    width: "85%",
                    minHeight: "100px",
                    maxHeight: "100px",
                    border: "solid 2px #999",
                  }}
                ></pre>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Playground;
